import React, { useEffect, useState } from 'react';
import Card from './card';
import pages from './data.json';
import usePageSelect from './hooks/usePageSelect';
import './App.css';
import scrollToTop from './utils/strollToTop';
import HelpModal from './help';

const pageSlugs = Object.keys(pages);
const totalPages = pageSlugs.length;
const PAGE_ANIMATION_DURATION = 1000; // ms

function App({ defaultCard, hasSeenHelp }) {
  const [selectedCard, setSelectedCard] = usePageSelect(defaultCard);
  const [transitioning, setTransitioning] = useState(false);
  const [transitioningTo, setTransitioningTo] = useState('basketball');
  const currentPageIndex = pageSlugs.indexOf(selectedCard);
  const prevPageIndex = (currentPageIndex + totalPages - 1) % totalPages;
  const nextPageIndex = (currentPageIndex + totalPages + 1) % totalPages;
  const prevPageSlug = pageSlugs[prevPageIndex];
  const nextPageSlug = pageSlugs[nextPageIndex];
  const [needHelp, showHelp, hideHelp] = useToggle(!hasSeenHelp);

  const [flash, setFlash] = useState(true)
  const flashPagination = () => {
    setFlash(true);
    setTimeout(() => setFlash(false), 500);
  };

  useEffect(() => {
    flashPagination();
  }, []);

  const goToPage = (p, direction) => {
    scrollToTop();
    
    setTimeout(() => {
      flashPagination();
      setTransitioningTo(p);
      setTransitioning(direction);
      
      setTimeout(() => {
        setTransitioning(false)
        setSelectedCard(p);
      }, PAGE_ANIMATION_DURATION);
    }, 100);
  };

  return (
    <div className="App">
      {needHelp && <HelpModal dismiss={hideHelp} />}
      <header className="App-header">
        <h1>
          Davis Family 2019
        </h1>
      </header>
      <section className={`current-card card-container ${transitioning || ''}`}>
        <Card {...pages[selectedCard]} slug={selectedCard}/>
      </section>
      {transitioning && <section className="next-card card-container">
        <Card {...pages[transitioningTo]} slug={transitioningTo} />
      </section>}
      <section className={`pagination ${flash ? 'flash' : ''}`}>
        <div className="left" onClick={() => goToPage(prevPageSlug, 'prev')}>&nbsp;</div>
        <div className="right" onClick={() => goToPage(nextPageSlug, 'next')}>&nbsp;</div>
      </section>
      <div onClick={showHelp}>
        help!!
      </div>
    </div>
  );
}

export default App;

function useToggle(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  return [value, () => setValue(true), () => setValue(false)];
}