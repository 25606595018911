import React from 'react';

export default function HelpModal({dismiss}) {
  return (
    <div className="help-overlay">
      <p onClick={dismiss}>
        click HERE to see pictures!!!
      </p>
      <p>
        click where the grey bars are on either side of the screen to go to the next/previous group of pictures. The bars will not be visible, but you can still click there.
      </p>
      <p>
        click a picture if you want to see a bigger version of it
      </p>
      <p>
        click "help" at the bottom of each page to see this screen again
      </p>
      <section className="pagination permanent">
        <div className="left">&nbsp;</div>
        <div className="right">&nbsp;</div>
      </section>
    </div>
  );
} 