import React from 'react';
import './card.css';
import Image from './image';
import Video from './video';

function Card({title, slug, blurb, media}) {
  return (
    <div className="card">
      <h1>{title}</h1>
      <div className="card-contents">
        {blurb && 
          <div className="blurb">{blurb}</div>
        }
        {media.map((group, i) => (
          <div key={`${slug}-media-group-${i}`} className="group">
            {group.caption && 
              <div className="caption">
                {group.caption}
              </div>
            }
            {group.photos && 

              <div className="photos">
                {group.photos.map((filename, i) => (
                  <Image key={`${slug}-picture-${i}`} filename={filename} />
                ))}
              </div>
            }
            {group.videos && 
              <div className="videos">
                {group.videos.map((filename, i) => (
                  <Video key={`${slug}-video-${i}`} filename={filename} />
                ))}
              </div>
            }
          </div>
          
        ))}
      </div>
    </div>
  );
}

export default Card;