import React from 'react';
import { getFull, getSmall } from '../utils/imageUrl';

export default function Image({ filename }) {
  const displayFilename = window.location.host.includes('localhost');

  return (
    <div>
      <a href={getFull(filename)} target="_blank" rel="noopener noreferrer">
        <img alt="" src={getSmall(filename)} />
      </a>
      {displayFilename && <div>{filename}</div>}
    </div>
  );
}