import React, { useRef } from 'react';
import { getVideo } from '../utils/imageUrl';

export default function Video({filename}) {
    const videoRef = useRef();
    return (
        <div>
            <video ref={videoRef} controls>
                <source src={getVideo(filename)} type="video/mp4"/>
            </video>
        </div>
    );
}